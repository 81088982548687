import "../assets/portfolio.css";
import Footer from "./Footer";
import NavBar from "./NavBar";

function Portfolio() {
  return (
    <div className="app">
      <NavBar/>
      <p className="content">Some content will soon be here.</p>
      <Footer />
    </div>
  );
}

export default Portfolio;
